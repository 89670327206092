<template>
  <div>
    <kendo-multiselect
      :ref="'citationMultiSelect'"
      :data-source="formDataSource"
      :data-value-field="'FormIdentity'"
      :data-text-field="'FormName'"
      :placeholder="'No fields selected...'">
    </kendo-multiselect>
  </div>
</template>

<script>
import FormDataSource from '@/assets/data/Form.json'

export default {
  name: 'assigned-forms-template',
  data () {
    return {
      formDataSource: FormDataSource
    }
  }
}
</script>
