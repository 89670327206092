<template>
  <div>
    <kendo-multiselect
      :ref="'stagesMultiSelect'"
      :data-source="stageDataSource"
      :data-value-field="'StageIdentity'"
      :data-text-field="'StageName'"
      :placeholder="'No fields selected...'">
    </kendo-multiselect>
  </div>
</template>

<script>
import StageDataSource from '@/assets/data/Stage.json'

export default {
  name: 'allowed-stages-template',
  data () {
    return {
      stageDataSource: StageDataSource
    }
  }
}
</script>
